.imageContainer {
  position: relative;
  width: 200px; /* Match the image width */
  height: 100px; /* Match the image height */
}

.photo {
  border-radius: 20px;
  width: 200px;
  height: 100px;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}

.svg {
  transform: rotate(-90deg);
}

.backgroundCircle {
  fill: none;
  stroke: #e6e6e6;
  stroke-width: 10;
}

.progressCircle {
  fill: none;
  stroke: #00aaff;
  stroke-width: 10;
  transition: stroke-dashoffset 0.35s;
}

.label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.7rem;
  font-weight: bold;
  color: white;
}
