.callingContainer {
  padding: 2rem 2.5rem;
  
}
.cancelBtn{
  margin: 2rem 0;
  button{
    all: unset;
    background-color: #DE3730;
    border-radius: 50%;
    padding: 1rem;
    cursor: pointer;
  }
}
.actionsBtn {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
   .btnWrapper{
    text-align: center;
    button {
    all: unset;
    background-color:#E5E6Ec ;
    padding: 1rem 1.1rem;
    border-radius: 50%;
    cursor: pointer;
    }
    &:first-child{
      button{
        background-color: #DE3730;
      }
    }
    p {
      font-weight: 500;
    }
  }
  .btnWDisabledrapper{
    text-align: center;
    button {
    all: unset;
    background-color:#DFE2EB ;
    padding: 1rem 1.1rem;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.5;
    }
    &:first-child{
      button{
        background-color: #DE3730;
        opacity: .5;
      }
    }
    p {
      font-weight: 500;
    }
  }

}
.actionEndCallBtn {
  margin-top: 14rem;
  display: flex;
  justify-content: space-around;
  
  gap: 5rem;
  button{
      all: unset;
      border-radius: 50%;
      cursor: pointer;
      padding: .7rem .8rem;
    &:first-child{
       background-color: rgba(128, 128, 128, 0.692);
    }
    &:last-child{
      background-color: #10B981;
    }
  }
}
//-------------------------modal-------------------------------------------------------
.modalContentCard {
  background-color: #e6e8ec;
  width:300px;
  // height: 200px;
  padding: 10px;
  border-radius: 20px;
  .closeBtn {
    text-align: right;
    cursor: pointer;
  }
}
.modalText {
  // strong {
  //   display: block;
  //   text-align: center;
  //   color: #1860A5;
  //   font-size: 1.1rem;
  // }
  p {
    text-align: center;
    color: #2e3033;
    font-size: 1.3rem;
  }
}
.actionBtn {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  button {
    height: 3rem;
    color: white;
    font-size: 1.3rem;
    border-radius: 15px;
    border:none;
    cursor: pointer;
    &:last-child{
      background-color: #03cb2f;
    }   
  }
}

@media screen and (max-width: 576px) {
  .callingContainer {
     padding: .5rem 2.5rem;
  }
   .actionEndCallBtn {
  margin-top: 7rem;
  display: flex;
  justify-content: space-around;
  
  gap: 5rem;
  button{
      all: unset;
      border-radius: 50%;
      cursor: pointer;
      padding: .7rem;
    &:first-child{
       background-color: rgba(128, 128, 128, 0.692);
    }
    &:last-child{
      background-color: #10B981;
    }
  }
}
}
@media screen and (max-width: 400px) {
  .callingContainer {
     padding: .5rem 1.5rem;
  }
  .actionEndCallBtn {
  margin-top: 6rem;
  display: flex;
  justify-content: space-around;
  gap: 5rem;
  button{
      all: unset;
      border-radius: 50%;
      cursor: pointer;
      padding: .7rem;
    &:first-child{
       background-color: rgba(128, 128, 128, 0.692);
    }
    &:last-child{
      background-color: #10B981;
    }
  }
}
}
