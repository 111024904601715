.DownloadBtnContainer {
 
  button{
    all: unset;
    background-color: #10b981;
    padding: .5rem .6rem;
    border-radius: 50%;
    cursor: pointer;
  }
 
}
.modalBtn{
    width: 100%;
    height: 3rem;
    color: white;
    font-size: 1.3rem;
    border-radius: 15px;
    border:none;
    cursor: pointer;
    background-color: #1860A5;;
}  