.keypadBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    all: unset;
    background-color: #e6e8ec;
    font-size: 3rem;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    text-align: center;
    flex: 1;
    cursor: pointer;
    transition: all 0.3s;
    &:hover{
      transform: scale(0.9);
    }
    }
    &:nth-child(10){
         button{
          font-size: 4.5rem;
      
      }
     
    }
}
@media screen and (max-width: 576px) {
.keypadBtnContainer{
 button {
   flex: 1;
    font-size: 2.2rem;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    text-align: center;  
    &:hover{
      background-color:#e6e8ec;
    }
} 
// &:nth-child(10){
//   button{
//     // display: none;
//    font-size: 2rem;
//    font-weight: 300;

// }

// }
}

}
@media screen and (max-width: 500px) {
  .keypadBtnContainer{
   button {
     flex: 1;
      font-size: 2rem;
      width: 85px;
      height: 85px;
      border-radius: 50%;
      text-align: center;  
       &:hover{
        background-color:#e6e8ec; 
      }
  } 
  &:nth-child(10){
    button{
     font-size: 80px;
     font-weight: 100;
  
  }
  
  }
  }
  
  }
@media screen and (max-width: 350px) {
.keypadBtnContainer{
 button {
   flex: 1;
    font-size: 1.5rem;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    text-align: center;  
    &:hover{
      background-color:#e6e8ec;
   }
} 
}
}
@media screen and (max-width: 330px) {
  .keypadBtnContainer{
   button {
     flex: 1;
      font-size: 1.5rem;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      text-align: center;  
 
     &:hover{
        background-color:#e6e8ec;
      }
  } 
  &:nth-child(10){
    button{
     font-size: 3.5rem;
     font-weight: 300;
  
  }
  
  }
  }
  }