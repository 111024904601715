.callingStateContainer{
  // border: 1px solid red;
  margin-bottom: 3rem;
  p{
    &:first-child{
      font-size:1.4rem;
    }
   
  }
}
.callingStateContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p{
      text-align: center;
      font-size: 1.2rem;
      color: #43474E;
    }
}
.talkingStateContainer{
  margin-bottom: 3rem;
 .talkingStateContent {
  display: flex;
 
  justify-content: space-between;
  p {
    &:first-child{
      all: unset;
    color: #43474eda;
    font-size: 1.1rem;  
    }
    &:last-child{
      all: unset;
      display: block;
      color: #43474ef5;
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0.5rem 0;
    }
      
    
  }
 }
 .talkingStatus {
  margin-top: 2rem;
  p {
    font-size: 1.5rem;
    font-weight: 500;
     
      text-align: center;
  }
 }
}
@media screen and (max-width: 576px) {
  .callingStateContainer{
  // border: 1px solid red;
  margin-bottom: 2rem;
  p{
    &:first-child{
      font-size:1.4rem;
    }
   
  }
}
}
@media screen and (max-width: 400px) {
  .callingStateContainer{
  // border: 1px solid red;
  margin-bottom: 1rem;
  p{
    &:first-child{
      font-size:1.1rem;
    }
   
  }
}
}