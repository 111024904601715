.inputContainer {
  margin: 1rem 0;
   width: 100%;
  height: 7rem;
  text-align: center;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
 color: #73777F;

 
}
.fadeInAnimation  {
  animation: fade 0.3s ease-in-out;
 }
.keypadContainer {
 padding: 0 2rem;
}
.keysNumbers {
   display: flex;
  justify-content: space-between;
 gap:3rem;
  flex-wrap: wrap;
  margin-top: 3rem;
}
.actionContianer {
  margin: 1.5rem 0;
  display: flex;
  justify-content:space-between;
  align-items: center;
  // gap: 8rem;
  button{
    &:first-child{
      visibility: hidden;
    }
    &:last-child{
      all: unset;
      width: 20px;
      max-height: 30px;
      background-color: #e6e8ec;
      padding: 2px 10px 5px 20px;
      border-radius: 40px 0 0 40px;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
    
    }
  }
 
}
 .dialBtnDisable{
   all: unset;
    background-color: #03cb2e71!important;
    border-radius: 50%;
    padding: 1.2rem;
    cursor: pointer;
    margin-left: 40px;
    // margin: 0 auto;
  }
//modal styles------------------------------------------------------------------------------------
.modalContentCard {
  background-color: #e6e8ec;
  width:250px;
  // height: 200px;
  padding: 10px;
  border-radius: 20px;
  .closeBtn {
    text-align: right;
    cursor: pointer;
  }
}
.modalText {
  strong {
    display: block;
    text-align: center;
    color: #1860A5;
    font-size: 1.1rem;
  }
  p {
    text-align: center;
    color: #73777F;
    font-size: 1rem;
  }
}
.actionBtn {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  button {
    height: 3rem;
    color: white;
    font-size: 1.3rem;
    border-radius: 15px;
    border:none;
    cursor: pointer;
    &:first-child {
      background-color: #03cb2f;
    }
   
  }
}
//responsive Styles---------------------------------------------------------------
@media screen and (max-width: 992px) {

.keysNumbers{
  justify-content: space-between;
  gap:2rem;
}

}
@media screen and (max-width: 768px) {
  .inputContainer {
    height: 6rem;
      font-size: 1.5rem;
  }
.keysNumbers{
  justify-content: space-between;
  gap:2rem;
}
.actionContianer {
  margin: 3rem 0;
  display: flex;
  justify-content:space-between;
  align-items: center;
  // gap: 8rem;
 
}
}
@media screen and (max-width: 400px) {
  .inputContainer {
    height: 1.3rem;
      font-size: 1.6rem;
      padding-top: 10px;
  }
.keysNumbers{
  justify-content: space-between;
  gap:.8rem;
}
.actionContianer {
  margin: 1rem 0;
  display: flex;
  justify-content:space-between;
  align-items: center;
  // gap: 8rem;
 
}
}
@media screen and (max-width: 330px) {
  .inputContainer {
    height: .7rem;
      font-size: 1.6rem;
  }
.keysNumbers{
  justify-content: space-between;
  gap:.7rem;
}
.actionContianer {
  margin: .9rem 0;
  display: flex;
  justify-content:space-between;
  align-items: center;
  // gap: 8rem;
 
}
}


 @keyframes fade {
  0% {
   opacity: 0;
   transform: scale(.5)
  }
  // 50% {
  //   opacity: 0.5;
  //   transform: rotate(90deg) scale(1.2);
  
  // }
  100% {
    opacity: 1;
       transform:  scale(1);
     
  }
}