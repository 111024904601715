.App {
  max-width: 500px;
 border: 1px solid rgba(0, 0, 0, 0.233);
  margin: 0 auto;  
}
.grecaptcha-badge{
 visibility: hidden!important;
}

/* modal */
.modalContainer {
 background-color: #e6e8ec;
  width:250px;
 
  padding: 10px;
  border-radius: 20px;
}
.closeBtn {
   text-align: right;
    cursor: pointer;
}
.modalText>strong {
  
    display: block;
    text-align: center;
    color: #a5182b;
    font-size: 1.1rem; 
}
.modalText>p {
 text-align: center;
    color: #73777F;
    font-size: 1rem;
} 
.actionBtn {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.actionBtn >button {
   height: 3rem;
    color: white;
    font-size: 1.3rem;
    border-radius: 15px;
    border:none;
    cursor: pointer;
    background-color: #03cb2f;
}

@media screen and (max-width: 900px) {
  .App {
      max-width: 400px;
       border: none;
  }
  
}
@media screen and (max-width: 576px) {
  .App {
      max-width: 400px;
       border: none;
  }
  
}


@media screen and (max-width: 400px) {
  .App {
      /* max-width: 350px; */
     border: none;
  } 
}
@media screen and (max-width: 300px) {
  .App {
      /* max-width: 250px; */
       border: none;
  }
 
  
}