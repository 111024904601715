.callingContainer {
  padding: 2rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cancelBtn{
  margin: 2rem 0;
  button{
    all: unset;
    background-color: #DE3730;
    border-radius: 50%;
    padding: 1rem;
    cursor: pointer;
  }
}
.userInformContainer {
    background-color: #e6e8ec;
  width:250px;
  // height: 200px;
  padding: 10px;
  border-radius: 20px;
  p {
    text-align: left;
    color: #333;
    font-size: 1rem;
  }
  
}
.actionBtn {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  
  gap: 5rem;
  button{
      all: unset;
      border-radius: 50%;
      cursor: pointer;
      padding: .7rem;
    &:first-child{
       background-color: rgba(128, 128, 128, 0.692);
    }
    &:last-child{
      background-color: #10B981;
    }
  }
}

@media screen and (max-width: 576px) {

  .callingContainer {
     padding: .5rem 2.5rem;
  }
}

@media screen and (max-width: 350px) {
  .callingContainer {
     padding: .5rem 1.5rem;
  }
}
