// .container{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   img {
//     max-width:100%;
//   }
// }
.container {
  background-image: url('../../assets/images/rotate.jpg');
   width: 100%;
  height: 100vh; /* Full viewport height */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden; /* Prevent scrolling */

}
//--------------------------
// .container {
//   text-align: center;
//   background-color: #f8f8f8;
//   // padding: 20px;
//   border-radius: 10px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   max-width: 400px;
//   margin: 0 auto;

//   @media (max-width: 600px) {
//     // padding: 10px;
//     max-width: 100%;
//     border-radius: 0;
//     box-shadow: none;
//   }
// }

// .header {
//   background-color: #e53935;
//   color: white;
//   padding: 20px;
//   border-radius: 10px 10px 0 0;

//   @media (max-width: 600px) {
//     padding: 15px;
//   }
// }

// .logo {
//   width: 50px;
//   height: 50px;

//   @media (max-width: 600px) {
//     width: 40px;
//     height: 40px;
//   }
// }

// .instructions {
//   background-color: white;
//   padding: 20px;
//   border-radius: 0 0 10px 10px;

//   @media (max-width: 600px) {
//     padding: 15px;
//   }
// }

// .instructions h2 {
//   color: #333;
//   font-size: 18px;

//   @media (max-width: 600px) {
//     font-size: 16px;
//   }
// }

// .instructions p {
//   color: #666;

//   @media (max-width: 600px) {
//     font-size: 14px;
//   }
// }

// .instructions ol {
//   text-align: left;
//   padding-left: 20px;

//   @media (max-width: 600px) {
//     padding-left: 15px;
//   }
// }

// .instructions li {
//   margin-bottom: 10px;
//   font-size: 16px;

//   @media (max-width: 600px) {
//     font-size: 14px;
//   }
// }

// .icon {
//   background-color: #e53935;
//   color: white;
//   padding: 5px 10px;
//   border-radius: 5px;

//   @media (max-width: 600px) {
//     padding: 4px 8px;
//   }
// }

// .instructionImage {
//   width: 100%;
//   margin-top: 10px;

//   @media (max-width: 600px) {
//     margin-top: 8px;
//   }
// }
