//-------------------------modal-------------------------------------------------------
@supports (-webkit-touch-callout: none) {
  .inputArea textarea {
    font-size: 16px;
  }
}

.modalContentCard {
  background-color: #fff;
  width:450px;
  height: 500px;
  padding: 10px;
  border-radius: 20px;
  position: relative;
  .closeBtn {
    text-align: right;
    cursor: pointer;
  }
}
.chat {
  // border: 1px solid black;
  background-color: #fff;
   height: 75%;
    padding: 0 1rem;
      display: flex;
    flex-direction: column;
    overflow: auto;
  .warningBox {
    background-color: #FFEDD5;
    padding: .1rem 1.1rem;
    border-radius: 15px;
    margin: 1.5rem 0;
    p {
    text-align: left;
    color: #C2410C;
    font-size: 1.3rem;    
    }
  }
  
}
.messages {
  display: flex;
  flex-direction: column;
}
.myMessage, .partnerMessage {
  max-width: 400px;
  margin: .5rem 0;
  padding: 5px 20px;
  font-size: 15px;
  font-weight: 500;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  white-space: pre-wrap;
    word-break: break-word; 
  overflow-wrap: break-word;
}

.myMessage {
  background: #1860A5;
  color: #fff;
  align-self: flex-end;
  border-bottom-right-radius: 0;
  span {
    display: inline-block;
    margin:5px 0 ;
  }

  p {
    &:last-child {
      // margin: 5px;
      text-align: right;
      color: #A0A1A1;
      margin:5px 0!important;
    }
  }
}

.partnerMessage {
  background: #F2F4FB;
  color: #000;
  align-self: flex-start;
  border-bottom-left-radius: 0;
  span {
    display: inline-block;
    margin:5px 0 ;
  }
  p {
    &:last-child {
      text-align: right;
      color: #A0A1A1;
      margin:5px 0!important;
    }
  }
}
   .inputArea {
    // border: 1px solid black;
    display: flex;
    background: #fff;
    padding: 0.3rem;
    
    input {
      touch-action: manipulation;
    }
    textarea {
      display: flex;
      flex: 1;
      padding: 0.5rem 2rem;
      border: 1px solid #ccc;
      border-radius: 40px;
      margin-right: 0.5rem;
      width: 80%;
      height: 3.5rem;
      resize: none;
      font-size: 1rem;
      background-color: #F2F4FB;
      touch-action: manipulation;
      align-items: center;
      // text-align: center; /* Centers the text horizontally */
      line-height: 2rem; /* Matches height to center text vertically */
      box-sizing: border-box;
     
    }

    button {
      padding: 0.5rem 1rem;
      background: #F2F4FB;
      color: #fff;
      border: none;
      border-radius: 50px;
      cursor: pointer;
    }
  }

.actionBtn {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  button {
    height: 3rem;
    color: white;
    font-size: 1.3rem;
    border-radius: 15px;
    border:none;
    cursor: pointer;
    &:last-child{
      background-color: #03cb2f;
    }   
  }
}

//----------responsive styles
@media screen and (max-width: 450px) {
 .modalContentCard {
  width:320px;
  height: 460px;
}
.chat { 
  .warningBox {
   p {
    font-size: 1rem;    
    }
  }
  
}
.myMessage, .partnerMessage {
  max-width: 270px;
  padding: 5px 15px;
}
.inputArea{
  textarea{
    font-size: 0.8rem;
  }
}


}
@media screen and (max-width: 330px) {
 .modalContentCard {
  width:260px;
  height: 390px;
}
.chat { 
  .warningBox {
   p {
    font-size: 1rem;    
    }
  }
  
}
.myMessage, .partnerMessage {
  max-width: 220px;
  padding: 5px 15px;
}

  .inputArea {
    // border: 1px solid black;
    display: flex;
    background: #fff;
    padding: 1rem;
    

    textarea {
      flex: 1;
      padding: 0.5rem 1rem;
      border: 1px solid #ccc;
      border-radius: 40px;
      margin-right: 0.5rem;
      width: 60%;
      height: rem;
      resize: none;
      font-size: .7rem;
    }

    button {
      padding: 0.5rem 1rem;
      background: #F2F4FB;
      color: #fff;
      border: none;
      border-radius: 50px;
      cursor: pointer;
    }
  }
}