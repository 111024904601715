.cancelBtn{
  margin: 2rem 0;
  button{
    all: unset;
    background-color: #DE3730;
    border-radius: 50%;
    padding: 1rem 1.05rem;
    cursor: pointer;
  }
}
@media screen and (max-width: 400px) {
  .cancelBtn{
  margin: 2rem 0;
  button{
    all: unset;
    background-color: #DE3730;
    border-radius: 50%;
    padding: .7rem .8rem;
    cursor: pointer;
  }
}
}
@media screen and (max-width: 330px) {
  .cancelBtn{
  margin: 1rem 0rem;
  button{
    all: unset;
    background-color: #DE3730;
    border-radius: 50%;
    padding: .7rem .8rem;
    cursor: pointer;
  }
}
}