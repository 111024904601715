.callingContainer {
  padding: 2rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.cancelBtn{
  margin: 2rem 0;
  button{
    all: unset;
    background-color: #DE3730;
    border-radius: 50%;
    padding: 1rem;
    cursor: pointer;
  }
}

@media screen and (max-width: 576px) {

  .callingContainer {
     padding: .5rem 2.5rem;
  }
}
@media screen and (max-width: 400px) {
  .callingContainer {
     padding: .5rem 1.5rem;
  }
}


