.modalOverlay {
   position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.767);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000
}
// .modal {
//   background-color: white;
//   border-radius: 8px;
//   padding: 20px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
// }