.avatarWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img{
    width: 250px;
    height: 250px;
  }
  p {
    font-size: 2rem;
    font-weight: 400;
  }
  span {
     font-size: 2rem;
    font-weight: 400;
  }
}
@media screen and (max-width: 576px) {
  .avatarWrapper {
  img{
    width: 200px;
    height: 200px;
  }
  p {
    font-size: 1.6rem;
    font-weight: 400;
  }
  span {
    font-size: 1.6rem;
    font-weight: 400;
  }
}
}

@media screen and (max-width: 400px) {

  .avatarWrapper {
  img{
    width: 120px;
    height: 120px;   
  }
  p {
    font-size: 1.4rem;
    font-weight: 400;
  }
   span {
    font-size: 1.4rem;
    font-weight: 400;
  }
}
}
@media screen and (max-width: 330px) {

  .avatarWrapper {
  img{
    width: 100px;
    height: 100px;   
  }
  p {
    font-size: 1.4rem;
    font-weight: 400;
  }
   span {
    font-size: 1.2rem;
    font-weight: 400;
  }
}
}