.myMessage, .partnerMessage {
    max-width: 200px;
    margin: .5rem 0;
    padding: 5px 20px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    white-space: pre-wrap;
      word-break: break-word; 
    overflow-wrap: break-word;
  }
  
  
  .myMessage {
    background: #1860A5;
    color: #fff;
    align-self: flex-end;
    border-bottom-right-radius: 0;
    span {
      display: inline-block;
      margin:5px 0 ;
    }
  
    p {
      &:last-child {
        // margin: 5px;
        text-align: right;
        color: #A0A1A1;
        margin:5px 0!important;
      }
    }
  }
  
  .partnerMessage {
    background: #F2F4FB;
    color: #000;
    align-self: flex-start;
    border-bottom-left-radius: 0;
    span {
      display: inline-block;
      margin:5px 0 ;
    }
    p {
      &:last-child {
        text-align: right;
        color: #A0A1A1;
        margin:5px 0!important;
      }
    }
  }

  .imageContainer {
    position: relative; 
    display: inline-block; 
  }
  
  .photo {
    border-radius: 20px;
    margin-top: 10px;
    width: 200px;
    height: 100px;
  }
  

  //----------responsive styles
@media screen and (max-width: 450px) {
    .modalContentCard {
     width:320px;
     height: 500px;
   }
   .chat { 
     .warningBox {
      p {
       font-size: 1rem;    
       }
     }
     
   }
   .myMessage, .partnerMessage {
     max-width: 270px;
     padding: 5px 15px;
   }
   .inputArea{
     textarea{
       font-size: 0.8rem;
     }
   }
   
   
   }
   @media screen and (max-width: 330px) {
    .modalContentCard {
     width:280px;
     height: 410px;
   }
   .chat { 
     .warningBox {
      p {
       font-size: 1rem;    
       }
     }
     
   }
   .myMessage, .partnerMessage {
     max-width: 220px;
     padding: 5px 15px;
   }
   
     .inputArea {
       // border: 1px solid black;
       display: flex;
       background: #fff;
       padding: 1rem;
       
   
       textarea {
         flex: 1;
         padding: 0.5rem 1rem;
         border: 1px solid #ccc;
         border-radius: 40px;
         margin-right: 0.5rem;
         width: 60%;
         height: rem;
         resize: none;
         font-size: .7rem;
       }
   
       button {
         padding: 0.5rem 1rem;
         background: #F2F4FB;
         color: #fff;
         border: none;
         border-radius: 50px;
         cursor: pointer;
       }
     }
   }